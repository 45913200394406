import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const ResetPassword = () => {
  const token = useParams().token;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErorrMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      window.location.href = '/dashboard/links';
      return true;
    }
    setIsLoggedIn(false);
  }, []);

  const validate = () => {
    if (password !== confirmPassword) {
      setErorrMessage('Confirmation password does not match!');
      return false;
    } else {
      setErorrMessage('');
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSuccess(false);
    if (!validate()) return false;

    const dt = {
      password: password,
      passwordConfirmation: confirmPassword,
    };

    setErorrMessage('');
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/users/reset-password`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: dt,
    })
      .then((reply) => {
        setSubmitLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        if (error.response) {
          setErorrMessage(error.response.data);
        } else {
          setErorrMessage('An error occured, please try again');
        }
        setSubmitLoading(false);
        setIsSuccess(false);
      });
  };

  if (isLoggedIn) return <></>;

  return (
    <div className="flex justify-center min-h-screen bg-gray-50 mt-16 md:mt-0 md:pt-16">
      <div className="bg-white md:bg-gray-50">
        <div className="flex justify-center mb-6">
          <span className="border-2 border-black px-3 py-2 font-bold text-xl">
            Link Profile
          </span>
        </div>
        <div className="bg-white px-8 py-10 w-screen md:w-96 md:rounded-sm">
          <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
            <h2 className="text-lg font-bold mb-2">Reset Password</h2>
            {errorMessage.trim() !== '' && (
              <span className="text-red-500">* {errorMessage}</span>
            )}
            {isSuccess && (
              <span className="text-green-500">
                Recover success! Please login.{' '}
                <a href="/login" className="font-bold text-green-500 underline">
                  Login
                </a>
              </span>
            )}
            <input
              className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <input
              className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <button
              className="auth-button px-3 py-2 rounded-md w-full text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
              disabled={
                (password === '' && confirmPassword === '') || submitLoading
              }
            >
              Submit
            </button>
          </form>
        </div>
        <div className="mt-4 text-gray-700 text-sm text-center pb-16 xs:mt-0">
          Already have an account?{' '}
          <Link to="/login" className="underline hover:no-underline font-bold">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
