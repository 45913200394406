import _ from 'lodash';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import LinkForm from '../../components/dashboard/link/LinkForm';
import LinkSectionForm from '../../components/dashboard/link/LinkSectionForm';
import ListLink from '../../components/dashboard/link/ListLink';
import SocialMedia from '../../components/dashboard/link/SocialMedia';
import { LINK_TYPE } from '../../utils/Constants';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getAllData as _getAllData,
  addData as _addData,
  updateData as _updateData,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';

const customStyles = {
  overlay: {
    zIndex: '100',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
};

Modal.setAppElement('#root');

const Link = ({ getAllData, addData, updateData, deleteData }) => {
  const hiddenFileInput = useRef(null);
  const [inputTitle, setInputTitle] = useState('');
  const [inputUrl, setInputUrl] = useState('');
  const [inputImgFile, setInputImgFile] = useState(null);
  const [isButton, setIsButton] = useState(true);
  const [isShow, setIsShow] = useState(true);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [modalType, setModalType] = useState(LINK_TYPE.LINK);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['LINKS']).then((res) => {
      setItems(_.orderBy(res.data, ['position'], ['asc']));
    });
  }, []);

  function openModal(newLink = true, linkId = null, type = LINK_TYPE.LINK) {
    if (newLink === false) {
      // edit link
      setIsEdit(true);
      const linkItem = items.find((it) => it.id === linkId);
      setInputTitle(linkItem.title);
      setInputUrl(linkItem.url);
      setInputImgFile(linkItem.img);
      setIsShow(linkItem.isShow);
      setIsButton(linkItem.isButton);
    } else {
      setIsEdit(false);
    }
    setEditId(linkId);
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setInputTitle('');
    setInputUrl('');
    setInputImgFile(null);
    setIsOpen(false);
    setIsDelete(false);
  }

  const handleSelectImg = () => {
    hiddenFileInput.current.click();
  };

  const handleAddLink = async (e) => {
    e.preventDefault();
    const id = window.localStorage.getItem('id');
    let dt = {
      type: modalType,
      title: inputTitle,
      url: inputUrl,
      img: null,
      isShow: true,
      isButton: false,
      position: 1,
      countClick: 0,
    };

    setSubmitLoading(true);
    try {
      if (inputImgFile) {
        // upload thumbnail or link picture
        const fileData = new FormData();
        fileData.append('file', inputImgFile);

        const res = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/links/${id}/files`,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'access_token'
            )}`,
          },
          data: fileData,
        });
        dt['img'] = res.data.filename;
      }
      const addRes = await addData(dt);
      const temp = items;
      temp.unshift(addRes.data);
      let i = 0;
      const finalItems = await Promise.all(
        temp.map(async (item) => {
          i++;
          const itemId = item.id;
          await updateData(itemId, { ...item, position: i });
          return { ...item, position: i };
        })
      );
      setItems(finalItems);
      setSubmitLoading(false);
      closeModal();
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
    }
  };

  const handleUpdateLink = async (e) => {
    e.preventDefault();
    const id = window.localStorage.getItem('id');
    const dt = {
      id: editId,
      type: modalType,
      title: inputTitle,
      url: inputUrl,
      img: inputImgFile,
      isShow: isShow,
      isButton: isButton,
    };

    setSubmitLoading(true);
    try {
      if (inputImgFile && typeof inputImgFile !== 'string') {
        // upload thumbnail or link picture
        const fileData = new FormData();
        fileData.append('file', inputImgFile);
        const res = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/links/${id}/files`,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'access_token'
            )}`,
          },
          data: fileData,
        });
        dt['img'] = res.data.filename;
      }
      const updateRes = await updateData(editId, dt);
      const newItems = items.map((it) => {
        if (it.id == editId) {
          return updateRes.data;
        }
        return it;
      });

      setItems(newItems);
      setSubmitLoading(false);
      closeModal();
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      return false;
    }
  };

  const handleDeleteLink = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    await deleteData(RESOURCE_NAMES['LINKS'], editId);
    const temp = items.filter((it) => it.id != editId);
    let i = 0;
    const finalItems = await Promise.all(
      temp.map(async (item) => {
        i++;
        const itemId = item.id;
        await updateData(itemId, { ...item, position: i });
        return { ...item, position: i };
      })
    );
    setItems(finalItems);
    setSubmitLoading(false);
    closeModal();
  };

  return (
    <div>
      <div className="flex flex-row space-x-4">
        <button
          className="auth-button px-3 py-3 rounded-md w-3/5 md:w-3/4 text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
          onClick={openModal}
        >
          + Add new link
        </button>
        <button
          className="border border-blue-500 text-blue-500 px-3 py-3 rounded-md w-2/5 md:w-1/4 text-sm text-white font-semibold tracking-widest duration-200 ease-in-out hover:border-gray-800 hover:text-gray-800 disabled:opacity-40 disabled:pointer-events-none"
          onClick={() => openModal(true, null, LINK_TYPE.SECTION)}
        >
          + Section
        </button>
      </div>
      <div className="flex flex-col space-y-4 my-4">
        <ListLink
          items={items}
          setItems={setItems}
          openModal={openModal}
          updateData={updateData}
        />
        <SocialMedia />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={!isEdit ? 'Add new link modal' : 'Edit link modal'}
      >
        {modalType === LINK_TYPE.LINK ? (
          <LinkForm
            closeModal={closeModal}
            inputTitle={inputTitle}
            setInputTitle={setInputTitle}
            inputUrl={inputUrl}
            setInputUrl={setInputUrl}
            inputImgFile={inputImgFile}
            setInputImgFile={setInputImgFile}
            handleSelectImg={handleSelectImg}
            handleAddLink={handleAddLink}
            handleUpdateLink={handleUpdateLink}
            isEdit={isEdit}
            hiddenFileInput={hiddenFileInput}
            isDelete={isDelete}
            setIsDelete={setIsDelete}
            handleDeleteLink={handleDeleteLink}
            isButton={isButton}
            setIsButton={setIsButton}
            isShow={isShow}
            setIsShow={setIsShow}
            submitLoading={submitLoading}
          />
        ) : (
          <LinkSectionForm
            closeModal={closeModal}
            inputTitle={inputTitle}
            setInputTitle={setInputTitle}
            handleAddLink={handleAddLink}
            handleUpdateLink={handleUpdateLink}
            isEdit={isEdit}
            isDelete={isDelete}
            setIsDelete={setIsDelete}
            handleDeleteLink={handleDeleteLink}
            isShow={isShow}
            setIsShow={setIsShow}
            submitLoading={submitLoading}
          />
        )}
      </Modal>
    </div>
  );
};

export default connect(null, {
  getAllData: _getAllData,
  addData: _addData(RESOURCE_NAMES['LINKS']),
  updateData: _updateData(RESOURCE_NAMES['LINKS']),
  deleteData: _deleteData,
})(Link);
