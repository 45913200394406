export const SET_CURRENT_USER = 'current-user.set';
export const DEL_CURRENT_USER = 'current-user.delete';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;
