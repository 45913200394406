import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { NavLink } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import classNames from 'classnames';
import useWindowSize from '../../utils/useWindowSize';
import ScrollToTop from '../../utils/ScrollToTop';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import { getDataById as _getDataById } from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';
import { getCurrentUser as _getCurrentUser } from '../../store/actions/currentUser';
import { getCurrentUser as _currentUser } from '../../store/selectors/currentUser';

const DashboardLayout = ({
  children,
  getCurrentUser,
  currentUser,
  getDataById,
  user,
  link,
}) => {
  const [username, setUsername] = useState('');
  const [picture, setPicture] = useState('');
  const [width] = useWindowSize();
  const [isMobile, setMobile] = useState(false);
  const [showUserMenu, setUserMenu] = useState(false);
  const refUser = useOnclickOutside(() => setUserMenu(false));
  const [iframeKey, setIframeKey] = useState(1);

  /** Effect to detect if user is on mobile platform */
  useEffect(() => {
    if (width < 768 && !isMobile) setMobile(true);
    if (width >= 768 && isMobile) setMobile(false);
  }, [width, isMobile]);

  useEffect(() => {
    if (currentUser.id) {
      getDataById(RESOURCE_NAMES['PROFILES'], currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    const getData = async () => {
      if (localStorage.getItem('access_token')) {
        getCurrentUser();
      } else {
        window.location.href = '/login';
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (user && currentUser && user[currentUser.id]) {
      const userProfile = user[currentUser.id];
      setUsername(userProfile.username);
      if (userProfile.profile) {
        setPicture(
          userProfile.profile.picture ? userProfile.profile.picture : ''
        );
      }
    }
  }, [user, currentUser]);

  useEffect(() => {
    refreshIframe();
  }, [link, user]);

  const refreshIframe = () => {
    setIframeKey(iframeKey + 1);
  };

  const menuList = [
    {
      pageName: 'Links',
      route: '/dashboard/links',
    },
    {
      pageName: 'Design',
      route: '/dashboard/design',
    },
    {
      pageName: 'Profile',
      route: '/dashboard/profile',
    },
    {
      pageName: 'Upgrade',
      route: '/dashboard/upgrade',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="fixed top-0 w-screen flex flex-row justify-between items-center px-4 md:px-10 py-3 border-b bg-white z-50">
        {/* app logo */}
        <div>
          <span className="inline-block border-2 border-black px-3 py-2 font-bold text-xs">
            Link Profile
          </span>
        </div>
        {/* profile link */}
        <div>
          <a
            href={`${process.env.REACT_APP_URL}/${username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-pink-600 hover:text-pink-700"
            style={{
              textDecorationStyle: 'dashed',
              textDecorationThickness: '2px',
            }}
          >
            {process.env.REACT_APP_URL.replace(/^http(s?):\/\//i, '')}/
            {username}
          </a>
        </div>
        {/* account menu */}
        <div className="relative flex flex-row">
          <button
            type="button"
            onClick={() => setUserMenu(!showUserMenu)}
            className={`flex space-x-1 focus:outline-none ${
              showUserMenu && 'pointer-events-none'
            }`}
          >
            <Avatar
              name={username}
              size="35"
              round={true}
              src={
                picture
                  ? `${process.env.REACT_APP_API_URL}/images/${picture}`
                  : ''
              }
              color="#276dc6"
            />
          </button>

          {/*User Dropdown*/}
          <div
            ref={refUser}
            className={classNames(
              {
                'opacity-0 invisible pointer-events-none': !showUserMenu,
                'opacity-100 visible': showUserMenu,
              },
              'absolute right-0 top-12 w-48 px-6 py-4 text-left transition-all duration-500 ease-in-out text-sm bg-white border border-gray-300 rounded shadow-xl z-50'
            )}
          >
            <div className="mb-2 uppercase font-semibold text-xgreen">
              {username}
            </div>
            <div className="font-semibold text-gray-700 space-y-2">
              <a
                href="/dashboard/links"
                className="group flex flex-row w-full font-normal rounded transition duration-200 hover:bg-xbluetint"
              >
                <div className="menu-text self-center">Dashboard</div>
              </a>
              <button
                onClick={() => {
                  window.localStorage.clear();
                  window.location = '/login';
                }}
                type="button"
                className="group flex flex-row w-full rounded transition duration-200 hover:bg-xbluetint"
              >
                <div className="menu-text self-center">Sign out</div>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div
        className="flex flex-row"
        style={{
          paddingTop: '3.8rem',
        }}
      >
        <div
          className={classNames({
            'fixed flex flex-col items-end px-8 py-10 bg-white min-h-screen border-r': true,
            hidden: isMobile,
            'flex w-4/12': !isMobile,
          })}
        >
          <div
            style={{
              transform: 'scale(0.6)',
              transformOrigin: '350px 0',
              width: '350px',
              height: '723px',
              border: '9px solid black',
              borderRadius: '25px',
            }}
          >
            <iframe
              key={iframeKey}
              id="iframe"
              src={username && `${process.env.REACT_APP_URL}/${username}`}
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
        <div
          className={classNames(
            {
              'w-12/12': isMobile,
              'ml-33p w-8/12': !isMobile,
            },
            'flex flex-col overflow-y-auto min-h-screen bg-gray-100'
          )}
        >
          <nav className="fixed inset-x-0 px-4 md:px-0 md:static admin-navbar flex md:justify-center bg-white border-b py-3 space-x-4 overflow-x-auto">
            {menuList.map((menu, index) => (
              <NavLink
                key={index}
                to={menu.route}
                exact
                className="text-base text-gray-500 hover:text-gray-800"
              >
                {menu.pageName}
              </NavLink>
            ))}
          </nav>
          <ScrollToTop>
            <div className="w-screen md:w-full px-4 md:px-8 pt-16 md:pt-4">
              {children}
            </div>
          </ScrollToTop>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getResource(RESOURCE_NAMES['PROFILES'])(state),
  link: getResource(RESOURCE_NAMES['LINKS'])(state),
  currentUser: _currentUser()(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  getCurrentUser: _getCurrentUser,
})(DashboardLayout);
