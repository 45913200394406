import React from 'react';
import { Link } from 'react-router-dom';

const Confirmation = () => {
  return (
    <div className="flex justify-center min-h-screen bg-gray-50 mt-16 md:mt-0 md:pt-16">
      <div className="bg-white md:bg-gray-50">
        <div className="flex justify-center mb-6">
          <span className="border-2 border-black px-3 py-2 font-bold text-xl">
            Link Profile
          </span>
        </div>
        <div className="bg-white px-8 py-10 w-screen md:w-96 md:rounded-sm">
          <div className="flex flex-col space-y-5">
            <h2 className="text-lg font-bold">
              Thanks for signing up &#x1F973;
            </h2>
            <Link
              to="/dashboard/links"
              className="auth-button px-3 py-2 rounded-md w-full text-sm text-white font-semibold tracking-widest text-center"
            >
              CONTINUE TO DASHBOARD
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
