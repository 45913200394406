export const LINK_TYPE = {
  LINK: 'link',
  SECTION: 'section',
};

export const CARD_THEME = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

export const BACKGROUND_THEME = {
  COLOR: 'color',
  IMAGE: 'image',
};
