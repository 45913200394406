import React from 'react';

const Upgrade = () => {
  const userPlan = [
    {
      type: 'Business',
      benefits: [
        '1 master user with ability to create staff members',
        'Get Unlimited links on biom.ee',
        'Upload own branding/profile image',
        'See lifetime page views',
        'Thumbnail icon for each card',
        'Social media link',
        'Custom domain',
        'Remove watermark',
        'Manage staff members',
      ],
    },
    {
      type: 'Premium',
      benefits: [
        '1 User',
        'Get Unlimited links on biom.ee',
        'Upload own branding/profile image',
        'See lifetime page views',
        'Thumbnail icon for each card',
        'Social media link',
        'Custom domain',
        'Remove watermark',
      ],
    },
    {
      type: 'Free',
      benefits: [
        '1 User',
        'Get Unlimited links on biom.ee',
        'Upload own branding/profile image',
        'See total number of times each link clicked',
        'Thumbnail icon for each card',
        'Social media link',
      ],
    },
  ];

  return (
    <div className="pb-6">
      <h1 className="font-semibold text-2xl mb-5">Pick your plan</h1>
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        {userPlan.map((item) => (
          <div className="flex flex-col bg-white px-4 py-4 rounded md:w-64">
            <div className="border-b-2 mb-3">
              <h2 className="font-semibold text-xl">{item.type}</h2>
            </div>
            <ul className="flex list-disc flex-col space-y-4 mx-3 mb-8">
              {item.benefits.map((it) => (
                <li>{it}</li>
              ))}
            </ul>
            <div className="flex justify-center mt-auto">
              <button className="auth-button w-28 h-10 flex flex-row justify-center items-center rounded-md text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none">
                Select Plan
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Upgrade;
