import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  updateData as _updateData,
} from '../../store/actions/resources';
import { getCurrentUser as _currentUser } from '../../store/selectors/currentUser';
import { getResource } from '../../store/selectors/resources';
import { BACKGROUND_THEME, CARD_THEME } from '../../utils/Constants';

const Design = ({ getDataById, updateData, currentUser, profile }) => {
  const [cardTheme, setCardTheme] = useState(CARD_THEME.DEFAULT);
  const [cardColor, setCardColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const [bgTheme, setBgTheme] = useState(BACKGROUND_THEME.COLOR);
  const [bgColor, setBgColor] = useState('#fff');
  const hiddenFileInput = useRef(null);
  const [inputImgFile, setInputImgFile] = useState(null);
  const [submitBgLoading, setSubmitBgLoading] = useState(false);

  useEffect(() => {
    const id = currentUser.id;
    if (id) {
      getDataById(RESOURCE_NAMES['PROFILES'], id);
    }
  }, [currentUser]);

  useEffect(() => {
    const id = currentUser.id;
    if (id && profile[currentUser.id]) {
      const data = profile[currentUser.id];
      const theme = data.profile?.theme;
      setCardColor(_.get(theme, 'bgColor') || '#fff');
      setTextColor(_.get(theme, 'textColor') || '#fff');
      if (theme) {
        if (
          theme.bgColor &&
          theme.bgColor !== '' &&
          theme.textColor &&
          theme.textColor !== ''
        ) {
          setCardTheme(CARD_THEME.CUSTOM);
        }
      }
    }
  }, [profile]);

  const handleSelectImg = () => {
    hiddenFileInput.current.click();
  };

  const handleCardSave = (e) => {
    e.preventDefault();
    const data = {
      theme: {
        bgColor: '',
        textColor: '',
      },
    };

    if (cardTheme === CARD_THEME.CUSTOM) {
      data.theme.bgColor = cardColor;
      data.theme.textColor = textColor;
    }

    setSubmitLoading(true);
    updateData(currentUser.id, data)
      .then((res) => {
        setSubmitLoading(false);
        toast.success('Update theme success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: false,
          theme: 'colored',
        });
      })
      .catch((err) => {
        setSubmitLoading(false);
        toast.error('Update theme failed', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: false,
          theme: 'colored',
        });
      });
  };

  const handleBgSave = (e) => {
    e.preventDefault();
  };

  return (
    <div className="space-y-4">
      <div className="bg-white rounded px-5 pt-5 pb-10">
        <h2 className="text-2xl font-semibold border-b-2">Card Design</h2>
        <div className="block mb-4">
          <fieldset
            className="mt-2"
            value={cardTheme}
            onChange={(e) => setCardTheme(e.target.value)}
          >
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="radio"
                  value={CARD_THEME.DEFAULT}
                  checked={cardTheme === CARD_THEME.DEFAULT}
                />
                <span className="ml-2">Default Style</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="radio"
                  value={CARD_THEME.CUSTOM}
                  checked={cardTheme === CARD_THEME.CUSTOM}
                />
                <span className="ml-2">Custom Style</span>
              </label>
            </div>
          </fieldset>
        </div>
        {cardTheme === CARD_THEME.CUSTOM && (
          <div className="flex flex-col space-y-5 mb-8 md:flex-row md:space-x-10 md:space-y-0">
            <div>
              <p className="mb-2">Card Color:</p>
              <ChromePicker
                color={cardColor}
                onChange={(col) => setCardColor(col.hex)}
                disableAlpha
              />
            </div>
            <div>
              <p className="mb-2">Text Color:</p>
              <ChromePicker
                color={textColor}
                onChange={(col) => setTextColor(col.hex)}
                disableAlpha
              />
            </div>
          </div>
        )}

        <button
          className="auth-button w-28 h-10 flex flex-row justify-center items-center rounded-md text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
          onClick={handleCardSave}
          disabled={submitLoading}
        >
          Save
        </button>
      </div>
      <div className="bg-white rounded px-5 pt-5 pb-10">
        <h2 className="text-2xl font-semibold border-b-2">Background Design</h2>
        <div className="block mb-4">
          <fieldset
            className="mt-2"
            value={bgTheme}
            onChange={(e) => setBgTheme(e.target.value)}
          >
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="radio-bg"
                  value={BACKGROUND_THEME.COLOR}
                  checked={bgTheme === BACKGROUND_THEME.COLOR}
                />
                <span className="ml-2">Flat Color</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="radio-bg"
                  value={BACKGROUND_THEME.IMAGE}
                  checked={bgTheme === BACKGROUND_THEME.IMAGE}
                />
                <span className="ml-2">Custom Image</span>
              </label>
            </div>
          </fieldset>
        </div>
        {bgTheme === BACKGROUND_THEME.COLOR ? (
          <div className="mb-8">
            <ChromePicker
              color={bgColor}
              onChange={(col) => setBgColor(col.hex)}
              disableAlpha
            />
          </div>
        ) : (
          <div className="mb-8">
            <div
              className="inline-flex items-center justify-center w-44 h-52 ring ring-gray-500 rounded cursor-pointer"
              onClick={handleSelectImg}
            >
              {!inputImgFile ? (
                'Upload image'
              ) : (
                <img
                  className="w-44 h-52 object-cover rounded"
                  src={
                    typeof inputImgFile !== 'string'
                      ? URL.createObjectURL(inputImgFile)
                      : `${process.env.REACT_APP_API_URL}/images/${inputImgFile}`
                  }
                  alt="background preview"
                />
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={(e) => setInputImgFile(e.target.files[0])}
              style={{ display: 'none' }}
            />
          </div>
        )}
        <button
          className="auth-button w-28 h-10 flex flex-row justify-center items-center rounded-md text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
          onClick={handleBgSave}
          disabled={submitBgLoading}
        >
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: _currentUser()(state),
  profile: getResource(RESOURCE_NAMES['PROFILES'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  updateData: _updateData(RESOURCE_NAMES['PROFILES']),
})(Design);
