import axios from 'axios';
import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErorrMessage] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      window.location.href = '/dashboard/links';
      return true;
    }
    setIsLoggedIn(false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setErorrMessage('');
    const dt = {
      email: email,
      password: password,
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/users/login`,
      headers: {
        'content-type': 'application/json',
      },
      data: dt,
    })
      .then((reply) => {
        const parsedJwt = jwtDecode(reply.data.token);
        localStorage.setItem('access_token', reply.data.token);
        localStorage.setItem('id', parsedJwt.id);
        setSubmitLoading(false);
        window.location.href = `/dashboard/links`;
      })
      .catch((err) => {
        if (err.response) {
          setErorrMessage(err.response.data);
        } else {
          setErorrMessage('An error occured, please try again');
        }
        setSubmitLoading(false);
      });
  };

  if (isLoggedIn) return <></>;

  return (
    <div className="flex justify-center min-h-screen bg-gray-50 mt-16 md:mt-0 md:pt-16">
      <div className="bg-white md:bg-gray-50">
        <div className="flex justify-center mb-6">
          <span className="border-2 border-black px-3 py-2 font-bold text-xl">
            Link Profile
          </span>
        </div>
        <div className="bg-white px-8 py-10 w-screen md:w-96 md:rounded-sm">
          <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
            <h2 className="text-lg font-bold mb-2">Login</h2>
            {errorMessage.trim() !== '' && (
              <span className="text-red-500">* {errorMessage}</span>
            )}
            <input
              className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button
              className="auth-button px-3 py-2 rounded-md w-full text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
              disabled={email.trim() === '' || password === '' || submitLoading}
            >
              Login
            </button>
          </form>
          <div className="w-full text-center mt-2">
            <Link
              to="/forgot-password"
              className="text-gray-700 text-xs underline hover:no-underline font-semibold"
            >
              Forgot password?
            </Link>
          </div>
        </div>
        <div className="mt-4 text-gray-700 text-sm text-center pb-16 xs:mt-0">
          Doesn't have an account?{' '}
          <Link
            to="/register"
            className="underline hover:no-underline font-bold"
          >
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
