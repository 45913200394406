import React from 'react';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LinkForm = ({
  closeModal,
  inputTitle,
  setInputTitle,
  inputUrl,
  setInputUrl,
  inputImgFile,
  setInputImgFile,
  handleSelectImg,
  handleAddLink,
  handleUpdateLink,
  isEdit,
  hiddenFileInput,
  isButton,
  setIsButton,
  isShow,
  setIsShow,
  isDelete,
  setIsDelete,
  handleDeleteLink,
  submitLoading,
}) => {
  return (
    <div style={{ minWidth: '369px' }}>
      {!isDelete ? (
        <>
          <div className="flex flex-row pt-6 justify-between px-4 mb-8">
            <div className="font-bold">
              {!isEdit ? 'Add link' : 'Edit link'}
            </div>
            <button onClick={closeModal}>close</button>
          </div>
          <div>
            <div className="px-4 mb-8">
              <div className="flex flex-row space-x-4 ">
                <div className="flex flex-col space-y-3">
                  <input
                    className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
                    type="text"
                    name="title"
                    placeholder="Title"
                    onChange={(e) => setInputTitle(e.target.value)}
                    value={inputTitle}
                  />
                  <input
                    className="px-3 py-2 rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
                    type="text"
                    name="url"
                    placeholder="URL"
                    onChange={(e) => setInputUrl(e.target.value)}
                    value={inputUrl}
                  />
                </div>
                <div>
                  {!inputImgFile ? (
                    <button
                      className="w-20 h-20 border-2 border-gray-500 border-dashed rounded-md"
                      onClick={handleSelectImg}
                    >
                      Image
                    </button>
                  ) : (
                    <div className="relative">
                      <span
                        className="absolute right-0 bg-white px-1 rounded opacity-90 cursor-pointer hover:opacity-100"
                        onClick={() => setInputImgFile(null)}
                        style={{ marginTop: '2px', marginRight: '2px' }}
                      >
                        &#x2715;
                      </span>
                      <img
                        className="w-20 h-20 object-cover rounded"
                        src={
                          typeof inputImgFile !== 'string'
                            ? URL.createObjectURL(inputImgFile)
                            : `${process.env.REACT_APP_API_URL}/images/${inputImgFile}`
                        }
                        alt="preview"
                      />
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={(e) => setInputImgFile(e.target.files[0])}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              {isEdit && (
                <div className="flex flex-col space-y-3 mt-3">
                  <div className="flex flex-row items-center">
                    <span className="text-sm mr-1">Button</span>
                    <Switch
                      onChange={(val) => setIsButton(val)}
                      checked={isButton}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={23}
                      width={48}
                      className="react-switch"
                    />
                    <span className="text-sm ml-2">Tile</span>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <div
                      className="flex flex-col items-center"
                      onClick={() => setIsShow(!isShow)}
                    >
                      {isShow ? (
                        <FontAwesomeIcon
                          className="text-lg text-gray-600 cursor-pointer hover:text-gray-800"
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="text-lg text-gray-600 cursor-pointer hover:text-gray-800"
                          icon={faEye}
                        />
                      )}
                    </div>
                    <button
                      className="text-red-500 text-sm"
                      onClick={() => setIsDelete(true)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div>
              <button
                className="auth-button px-3 py-3 w-full text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
                onClick={!isEdit ? handleAddLink : handleUpdateLink}
                disabled={inputTitle === '' || inputUrl === '' || submitLoading}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="flex flex-col space-y-4 items-center mt-12 mb-12">
              <h1 className="text-2xl text-gray-900 font-semibold">
                Delete this data?
              </h1>
              <h1 className="text-gray-900 text-sm">
                All of this related data will be deleted.
              </h1>
            </div>
            <div>
              <button
                className="bg-green-600 px-3 py-3 w-1/2 text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none hover:bg-green-700"
                onClick={handleDeleteLink}
                disabled={submitLoading}
              >
                Delete
              </button>
              <button
                className="bg-red-500 px-3 py-3 w-1/2 text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none hover:bg-red-600"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LinkForm;
