import React, { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    const getData = async () => {
      if (localStorage.getItem('access_token')) {
        window.location.href = '/dashboard/links';
      } else {
        window.location.href = '/login';
      }
    };
    getData();
  }, []);
  return <></>;
};

export default Home;
