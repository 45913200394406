import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Component for ScrollToTop.
 *
 * Scrolls the content to top of page when the route updates.
 *
 * @component
 */
const ScrollToTop = ({ children }) => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);
  return children;
};

export default ScrollToTop;
