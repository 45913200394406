import React from 'react';
import { Redirect, Route } from 'react-router';

/**
 * Component for PrivateRoute.
 *
 * Redirects user to Auth page if user is not authenticated.
 *
 * @component
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const PrivateComponent = (props) => {
    if (window.localStorage.getItem('access_token'))
      return <Component {...props} />;
    else return <Redirect to="/login" />;
  };

  return <Route {...rest} render={PrivateComponent} />;
};

export default PrivateRoute;
