import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  getDataById as _getDataById,
  updateData as _updateData,
} from '../../../store/actions/resources';
import { toast, ToastContainer } from 'react-toastify';
import { getCurrentUser as _currentUser } from '../../../store/selectors/currentUser';
import { getResource } from '../../../store/selectors/resources';
import { RESOURCE_NAMES } from '../../../store/reducers/resources';
import { connect } from 'react-redux';
import _ from 'lodash';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';

const customStyles = {
  overlay: {
    zIndex: '100',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
};

Modal.setAppElement('#root');

const SocialMedia = ({ getDataById, updateData, currentUser, profile }) => {
  const [facebookInput, setFacebookInput] = useState('');
  const [instagramInput, setInstagramInput] = useState('');
  const [youtubeInput, setYoutubeInput] = useState('');
  const [linkedinInput, setLinkedinInput] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [socialLinks, setSocialLinks] = useState([
    {
      title: 'facebook',
      url: '',
      icon: faFacebook,
    },
    {
      title: 'instagram',
      url: '',
      icon: faInstagram,
    },
    {
      title: 'youtube',
      url: '',
      icon: faYoutube,
    },
    {
      title: 'linkedin',
      url: '',
      icon: faLinkedin,
    },
  ]);

  useEffect(() => {
    const id = currentUser.id;
    if (id) {
      getDataById(RESOURCE_NAMES['PROFILES'], id);
    }
  }, [currentUser]);

  useEffect(() => {
    const id = currentUser.id;
    if (id && profile[id]) {
      if (profile[id].profile?.socialMedia) {
        const { socialMedia } = profile[id].profile;
        const newSocialLinks = socialLinks.map((item) => {
          return {
            ...item,
            url: socialMedia[item.title],
          };
        });
        setSocialLinks(newSocialLinks);
        setInstagramInput(socialMedia.instagram);
        setYoutubeInput(socialMedia.youtube);
        setLinkedinInput(socialMedia.linkedin);
        setFacebookInput(socialMedia.facebook);
      }
    }
  }, [profile]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleAddSocialMedia = async () => {
    setIsSubmit(true);

    const socialMedia = {
      facebook: facebookInput,
      youtube: youtubeInput,
      linkedin: linkedinInput,
      instagram: instagramInput,
    };

    const id = currentUser.id;

    await updateData(id, { socialMedia })
      .then((res) => {
        setIsOpen(false);
        toast.success('Social media has been updated');
      })
      .catch((err) => {
        if (err.message?.errors) {
          err.message?.errors?.map((errorItem) => {
            toast.error(errorItem.msg);
          });
        } else {
          toast.error('There is something wrong, refresh this page');
        }
      });
    setIsSubmit(false);
  };

  const handleUrl = (link) => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  };

  return (
    <div className="pb-14">
      <div className="flex flex-col items-start space-y-1">
        <button
          className="text-2xl font-semibold text-gray-800 hover:text-black"
          onClick={openModal}
        >
          Social +
        </button>
        <div className="space-x-2">
          {socialLinks.map((item) => {
            if (item.url === null || item.url === '') {
              return null;
            } else {
              return (
                <a
                  key={item.title}
                  href={handleUrl(item.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-gray-900 text-2xl inline-block hover:text-black"
                    icon={item.icon}
                  />
                </a>
              );
            }
          })}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Social media modal"
      >
        <div style={{ minWidth: '369px' }}>
          <div className="flex flex-row pt-6 justify-between px-4 mb-8">
            <div className="font-bold">Social media</div>
            <button onClick={closeModal}>close</button>
          </div>
          <div>
            <div className="px-4 mb-8">
              <div className="flex flex-col space-y-2 md:space-y-4 pb-6">
                <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
                  <p className="w-32">Facebook:</p>
                  <div className="w-full">
                    <input
                      className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
                      type="text"
                      name="facebook"
                      placeholder="Facebook URL"
                      onChange={(e) => setFacebookInput(e.target.value)}
                      value={facebookInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
                  <p className="w-32">Instagram:</p>
                  <div className="w-full">
                    <input
                      className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
                      type="text"
                      name="instagram"
                      placeholder="Instagram URL"
                      onChange={(e) => setInstagramInput(e.target.value)}
                      value={instagramInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
                  <p className="w-32">Youtube:</p>
                  <div className="w-full">
                    <input
                      className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
                      type="text"
                      name="youtube"
                      placeholder="Youtube URL"
                      onChange={(e) => setYoutubeInput(e.target.value)}
                      value={youtubeInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
                  <p className="w-32">Linkedin:</p>
                  <div className="w-full">
                    <input
                      className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
                      type="text"
                      name="linkedin"
                      placeholder="Linkedin URL"
                      onChange={(e) => setLinkedinInput(e.target.value)}
                      value={linkedinInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="auth-button px-3 py-3 w-full text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
                onClick={handleAddSocialMedia}
                disabled={isSubmit}
              >
                {isSubmit ? 'Loading...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: _currentUser()(state),
  profile: getResource(RESOURCE_NAMES['PROFILES'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  updateData: _updateData(RESOURCE_NAMES['PROFILES']),
})(SocialMedia);
