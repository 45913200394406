import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LinkSectionForm = ({
  closeModal,
  inputTitle,
  setInputTitle,
  handleAddLink,
  handleUpdateLink,
  isEdit,
  isShow,
  setIsShow,
  isDelete,
  setIsDelete,
  handleDeleteLink,
  submitLoading,
}) => {
  return (
    <div style={{ minWidth: '369px' }}>
      {!isDelete ? (
        <>
          <div className="flex flex-row pt-6 justify-between px-4 mb-8">
            <div className="font-bold">
              {!isEdit ? 'Add Section' : 'Edit Section'}
            </div>
            <button onClick={closeModal}>close</button>
          </div>
          <div>
            <div className="px-4 mb-8">
              <div className="flex flex-col space-y-3">
                <input
                  className="px-3 py-2 w-full rounded-sm bg-gray-100 w-full text-sm focus:bg-white"
                  type="text"
                  name="title"
                  placeholder="Title"
                  onChange={(e) => setInputTitle(e.target.value)}
                  value={inputTitle}
                />
              </div>
              {isEdit && (
                <div className="flex flex-row items-center justify-between mt-5">
                  <div
                    className="flex flex-col items-center"
                    onClick={() => setIsShow(!isShow)}
                  >
                    {isShow ? (
                      <FontAwesomeIcon
                        className="text-lg text-gray-600 cursor-pointer hover:text-gray-800"
                        icon={faEyeSlash}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-lg text-gray-600 cursor-pointer hover:text-gray-800"
                        icon={faEye}
                      />
                    )}
                  </div>
                  <button
                    className="text-red-500 text-sm"
                    onClick={() => setIsDelete(true)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div>
              <button
                className="auth-button px-3 py-3 w-full text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
                onClick={!isEdit ? handleAddLink : handleUpdateLink}
                disabled={inputTitle === '' || submitLoading}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="flex flex-col space-y-4 items-center mt-12 mb-12">
              <h1 className="text-2xl text-gray-900 font-semibold">
                Delete this data?
              </h1>
              <h1 className="text-gray-900 text-sm">
                All of this related data will be deleted.
              </h1>
            </div>
            <div>
              <button
                className="bg-green-600 px-3 py-3 w-1/2 text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none hover:bg-green-700"
                onClick={handleDeleteLink}
                disabled={submitLoading}
              >
                Delete
              </button>
              <button
                className="bg-red-500 px-3 py-3 w-1/2 text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none hover:bg-red-600"
                onClick={closeModal}
                disabled={submitLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LinkSectionForm;
