import axios from '../axios';
import _ from 'lodash';
import { DEL_CURRENT_USER, SET_CURRENT_USER } from '../reducers/currentUser';

export const getCurrentUser = () => async (dispatch) => {
  const id = window.localStorage.getItem('id');
  const { data } = await axios.get(`/profiles/${id}`);

  return dispatch({
    type: SET_CURRENT_USER,
    data: data,
  });
};

export const logOut = () => async (dispatch) => {
  localStorage.removeItem('id');
  localStorage.removeItem('access_token');

  window.location.href = '/login';

  return dispatch({ type: DEL_CURRENT_USER });
};
