import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  updateData as _updateData,
  uploadProfilePhoto as _uploadProfilePhoto,
} from '../../store/actions/resources';
import { getCurrentUser as _currentUser } from '../../store/selectors/currentUser';
import { getResource } from '../../store/selectors/resources';

const Profile = ({
  getDataById,
  updateData,
  uploadProfilePhoto,
  currentUser,
  profile,
}) => {
  const [username, setUsername] = useState('');
  const [usernameInput, setUsernameInput] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [job, setJob] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [bio, setBio] = useState('');
  const [picture, setPicture] = useState('');
  const [isVCard, setIsVCard] = useState(true);

  const hiddenFileInput = useRef(null);
  const [inputImgFile, setInputImgFile] = useState(null);
  const [isPhotoUpload, setIsPhotoUpload] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  useEffect(() => {
    const id = currentUser.id;
    if (id) {
      getDataById(RESOURCE_NAMES['PROFILES'], id);
    }
  }, [currentUser]);

  useEffect(() => {
    const id = currentUser.id;
    if (id && profile[id]) {
      const data = profile[id];
      const profileItem = data.profile;

      setUsername(_.get(data, 'username') || '');
      setUsernameInput(_.get(data, 'username') || '');
      if (profileItem) {
        setFirstName(_.get(profileItem, 'firstName') || '');
        setLastName(_.get(profileItem, 'lastName') || '');
        const contact = profileItem.contact;
        if (contact) {
          setMobile(_.get(contact, 'mobile') || '');
          setPhone(_.get(contact, 'phone') || '');
          setFax(_.get(contact, 'fax') || '');
        }
        const company = profileItem.company;
        if (company) {
          setCompanyName(_.get(company, 'name') || '');
          setJob(_.get(company, 'job') || '');
        }
        setStreet(_.get(profileItem, 'street') || '');
        setCity(_.get(profileItem, 'city') || '');
        setState(_.get(profileItem, 'state') || '');
        setCountry(_.get(profileItem, 'country') || '');
        setWebsite(_.get(profileItem, 'webSite') || '');
        setBio(_.get(profileItem, 'bio') || '');
        setPicture(_.get(profileItem, 'picture') || '');
        setIsVCard(_.get(profileItem, 'isVcardQr', true));
      }
    }
  }, [profile]);

  const validate = () => {
    if (firstName.trim() === '' || lastName.trim() === '') {
      if (firstName.trim() === '') {
        setFirstNameError(true);
      }
      if (lastName.trim() === '') {
        setLastNameError(true);
      }
      toast.error('Update profile failed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: false,
        theme: 'colored',
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validate()) return false;

    const dt = {
      username: usernameInput.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      contact: {
        mobile: mobile.trim(),
        phone: phone.trim(),
        fax: fax.trim(),
      },
      company: {
        name: companyName.trim(),
        job: job.trim(),
      },
      street: street.trim(),
      city: city.trim(),
      state: state.trim(),
      country: country.trim(),
      webSite: website.trim(),
      bio: bio.trim(),
      isVcardQr: isVCard,
    };
    const userId = currentUser.id;
    setFirstNameError(false);
    setLastNameError(false);
    setSubmitLoading(true);
    updateData(userId, dt)
      .then((res) => {
        setSubmitLoading(false);
        toast.success('Update profile success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: false,
          theme: 'colored',
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .catch((err) => {
        setSubmitLoading(false);
        toast.error('Update profile failed', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: false,
          theme: 'colored',
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
  };

  const handleSelectImg = () => {
    hiddenFileInput.current.click();
  };

  const handleUploadPhoto = (e) => {
    e.preventDefault();
    setInputImgFile(e.target.files[0]);
    setIsPhotoUpload(true);
    const id = currentUser.id;
    const data = new FormData();
    data.append('file', e.target.files[0]);
    uploadProfilePhoto(id, data)
      .then((res) => {
        setIsPhotoUpload(false);
      })
      .catch((err) => {
        console.log(err);
        setIsPhotoUpload(false);
        toast.error('Upload profile photo failed', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: false,
          theme: 'colored',
        });
      });
  };

  return (
    <div className="flex flex-col space-y-2 md:space-y-4 pb-6">
      <div className="flex flex-col items-center space-y-2">
        <div>
          {isPhotoUpload && (
            <div
              className="absolute flex items-center justify-center rounded-full bg-white bg-opacity-50"
              style={{
                width: '80px',
                height: '80px',
              }}
            >
              <span className="text-xs text-black">Uploading...</span>
            </div>
          )}
          <Avatar
            name={username}
            size="80"
            round={true}
            src={
              inputImgFile
                ? URL.createObjectURL(inputImgFile)
                : `${process.env.REACT_APP_API_URL}/images/${picture}`
            }
            color="#276dc6"
          />
          <input
            type="file"
            accept="image/*"
            ref={hiddenFileInput}
            onChange={handleUploadPhoto}
            style={{ display: 'none' }}
          />
        </div>
        <div className="flex flex-row space-x-2">
          <button
            className="text-sm border font-semibold rounded border-gray-300 bg-white py-1 px-3"
            onClick={handleSelectImg}
          >
            Change photo
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        {firstNameError && (
          <span className="font-sm text-red-500">
            * First name can't be empty
          </span>
        )}
        {lastNameError && (
          <span className="font-sm text-red-500">
            * Last name can't be empty
          </span>
        )}
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Username:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="username"
            placeholder="Username"
            onChange={(e) => setUsernameInput(e.target.value)}
            value={usernameInput}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Your Name:</p>
        <div className="flex flex-row space-x-3 w-full">
          <input
            className={classNames(
              'w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm',
              { 'ring-2 ring-red-500': firstNameError }
            )}
            type="text"
            name="firstName"
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <input
            className={classNames(
              'w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm',
              { 'ring-2 ring-red-500': lastNameError }
            )}
            type="text"
            name="lastName"
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Bio:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="bio"
            placeholder="Bio"
            onChange={(e) => setBio(e.target.value)}
            value={bio}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0">
        <p className="w-32 md:pt-2">Contact:</p>
        <div className="flex flex-col w-full space-y-2">
          <div>
            <input
              className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
              type="text"
              name="mobile"
              placeholder="Mobile"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
          </div>
          <div className="flex flex-row space-x-3 w-full">
            <input
              className="w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm"
              type="text"
              name="phone"
              placeholder="Phone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <input
              className="w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm"
              type="text"
              name="fax"
              placeholder="Fax"
              onChange={(e) => setFax(e.target.value)}
              value={fax}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Company:</p>
        <div className="flex flex-row space-x-3 w-full">
          <input
            className="w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="companyName"
            placeholder="Company name"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
          />
          <input
            className="w-1/2 px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="job"
            placeholder="Your job"
            onChange={(e) => setJob(e.target.value)}
            value={job}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Street:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="street"
            placeholder="Street"
            onChange={(e) => setStreet(e.target.value)}
            value={street}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">City:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="city"
            placeholder="City"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">State:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="state"
            placeholder="State"
            onChange={(e) => setState(e.target.value)}
            value={state}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Country:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="country"
            placeholder="Country"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
        <p className="w-32">Website:</p>
        <div className="w-full">
          <input
            className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
            type="text"
            name="website"
            placeholder="Website"
            onChange={(e) => setWebsite(e.target.value)}
            value={website}
          />
        </div>
      </div>
      <div>
        <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
          <p className="w-32">QR Code:</p>
          <div className="w-full">
            <select
              name="qrcode"
              className="w-full px-3 py-2 border-2 rounded-sm bg-white text-sm"
              value={isVCard}
              onChange={(e) => setIsVCard(e.target.value)}
            >
              <option value={true}>vCard</option>
              <option value={false}>Biom.ee link</option>
            </select>
          </div>
        </div>
        <span className="inline-block text-sm text-gray-900 mx-2 md:mb-0 md:pl-28">
          Your QR Code is available at{' '}
          <a
            href={`${process.env.REACT_APP_URL}/${username}/qrcode`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:no-underline"
          >
            {process.env.REACT_APP_URL}/{username}
            /qrcode
          </a>
        </span>
      </div>
      <div className="flex flex-col items-center">
        <button
          className="auth-button w-28 h-10 flex flex-row justify-center items-center rounded-md text-sm text-white font-semibold tracking-widest opacity-100 disabled:opacity-40 disabled:pointer-events-none"
          onClick={handleSave}
          disabled={submitLoading || usernameInput === ''}
        >
          {!submitLoading ? (
            'Save Data '
          ) : (
            <Loader type="Oval" color="#fff" height={25} width={25} />
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: _currentUser()(state),
  profile: getResource(RESOURCE_NAMES['PROFILES'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  updateData: _updateData(RESOURCE_NAMES['PROFILES']),
  uploadProfilePhoto: _uploadProfilePhoto(RESOURCE_NAMES['PROFILES']),
})(Profile);
