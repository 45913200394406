import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

const UserDisplay = () => {
  const username = useParams().username;
  const [profileData, setProfileData] = useState([]);
  const [linkItems, setLinkItems] = useState([]);
  const [cardColor, setCardColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [background, setBackground] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [socialLinks, setSocialLinks] = useState([
    {
      title: 'facebook',
      url: '',
      icon: faFacebook,
    },
    {
      title: 'instagram',
      url: '',
      icon: faInstagram,
    },
    {
      title: 'youtube',
      url: '',
      icon: faYoutube,
    },
    {
      title: 'linkedin',
      url: '',
      icon: faLinkedin,
    },
  ]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/public/users/${username}`,
    })
      .then((res) => {
        if (res.data.links) {
          setLinkItems(_.orderBy(res.data.links, ['position'], ['asc']));
        }
        if (res.data.profile) {
          setProfileData(res.data.profile);
          const theme = res.data.profile.theme;
          if (theme) {
            setCardColor(theme.bgColor);
            setTextColor(theme.textColor);
          }
          const socmed = res.data.profile.socialMedia;
          if (socmed) {
            const newSocialLinks = socialLinks.map((item) => {
              return {
                ...item,
                url: socmed[item.title],
              };
            });
            setSocialLinks(newSocialLinks);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          setErrorMessage(err.response?.data.message);
        }
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const cardClick = (id) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/public/links/${id}`,
    });
  };

  const handleUrl = (link) => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  };

  if (isLoading) return <></>;

  if (!isLoading && isError)
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p className="font-semibold text-2xl ">
          {errorMessage
            ? errorMessage
            : 'An error occured, please refresh the page.'}
        </p>
      </div>
    );

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>
          {profileData && profileData.firstName
            ? `${profileData.firstName} ${profileData.lastName}`
            : username}
        </title>
        {profileData && profileData.bio && (
          <meta name="description" content={profileData.bio} />
        )}
      </Helmet>

      {/* Custom background Image */}
      {background !== '' && (
        <img
          className="fixed w-screen h-screen object-cover"
          src={`${process.env.REACT_APP_API_URL}/images/${background}`}
          alt="background image"
          style={{ zIndex: '-999' }}
        />
      )}

      {/* User Profile Display */}
      <div className="flex flex-col items-center pt-10 pb-24 w-full px-6 md:w-680px md:px-0 ml-auto mr-auto">
        <Avatar
          name={username}
          size="80"
          round={true}
          src={
            profileData &&
            `${process.env.REACT_APP_API_URL}/images/${profileData.picture}`
          }
          color="#276dc6"
        />
        <h1 className="text-base md:text-lg font-semibold mt-1">
          {profileData && profileData.firstName
            ? `${profileData.firstName} ${profileData.lastName}`
            : username}
        </h1>
        <p className="text-sm md:text-base">{profileData && profileData.bio}</p>
        {/* Link Display */}
        <div className="flex flex-col mt-8 w-full space-y-2">
          {linkItems.map((item, index) => {
            if (item.isShow) {
              if (item.type === 'section') {
                return (
                  <div
                    key={item.id}
                    className="text-center text-base md:text-lg font-semibold"
                  >
                    {item.title}
                  </div>
                );
              }
              if (item.type === 'link') {
                if (!item.isButton) {
                  return (
                    <a
                      key={item.id}
                      href={handleUrl(item.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-display relative flex flex-row items-center justify-center rounded-md bg-gray-900 text-white px-3 min-h-20"
                      style={{
                        backgroundColor: cardColor,
                        color: textColor,
                      }}
                      onClick={() => cardClick(item.id)}
                    >
                      {item.img && (
                        <img
                          className="absolute left-3 w-12 h-12 md:w-16 md:h-16 object-cover mr-6 rounded"
                          src={`${process.env.REACT_APP_API_URL}/images/${item.img}`}
                          alt="preview"
                        />
                      )}
                      <p className="text-center text-sm md:text-base font-semibold w-40 md:w-96">
                        {item.title}
                      </p>
                    </a>
                  );
                } else {
                  return (
                    <div
                      key={item.id}
                      className="relative flex flex-row items-center justify-center rounded-md bg-gray-900 text-white px-3 min-h-20"
                      style={{
                        backgroundColor: cardColor,
                        color: textColor,
                      }}
                    >
                      {item.img && (
                        <img
                          className="absolute left-3 w-16 h-16 object-cover mr-6 rounded"
                          src={`${process.env.REACT_APP_API_URL}/images/${item.img}`}
                          alt="preview"
                        />
                      )}
                      <span className="text-center w-96">
                        <a
                          href={handleUrl(item.url)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-center text-sm md:text-base font-semibold hover:underline"
                          onClick={() => cardClick(item.id)}
                        >
                          {item.title}
                        </a>
                      </span>
                    </div>
                  );
                }
              }
            }
          })}
        </div>
        {/* Social Media */}
        {socialLinks.length !== 0 && (
          <div className="mt-10 flex flex-row w-full justify-center space-x-10">
            {socialLinks.map((item) => {
              if (item.url === null || item.url === '') {
                return null;
              } else {
                return (
                  <a
                    key={item.title}
                    href={handleUrl(item.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-gray-900 text-3xl inline-block hover:text-black"
                      icon={item.icon}
                    />
                  </a>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDisplay;
