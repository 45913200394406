import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import vCardsJS from 'vcards-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const QRCodePage = () => {
  let vCard = vCardsJS();
  const username = useParams().username;
  const [qrValue, setQrValue] = useState('');
  const [profile, setProfile] = useState({});

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/public/users/${username}`,
    }).then((res) => {
      if (res.data.profile) {
        setProfile(res.data.profile);
        const vCardData = res.data.profile;
        if (vCardData.isVcardQr) {
          vCard.firstName = vCardData.firstName;
          vCard.lastName = vCardData.lastName;
          const contact = vCardData.contact;
          if (contact) {
            vCard.homePhone = contact.phone;
            vCard.cellPhone = contact.mobile;
            vCard.workFax = contact.fax;
          }
          const company = vCardData.company;
          if (company) {
            vCard.organization = company.name;
            vCard.role = company.job;
          }
          vCard.homeAddress.label = 'Home Address';
          vCard.homeAddress.street = vCardData.street;
          vCard.homeAddress.city = vCardData.city;
          vCard.homeAddress.stateProvince = vCardData.state;
          vCard.homeAddress.countryRegion = vCardData.country;
          vCard.workUrl = vCardData.webSite;
          setQrValue(vCard.getFormattedString());
        } else {
          setQrValue(`${process.env.REACT_APP_URL}/${username}`);
        }
      } else {
        setQrValue(`${process.env.REACT_APP_URL}/${username}`);
      }
    });
  }, []);

  return (
    <div className="flex flex-col items-center mt-10 space-y-5">
      <h1 className="text-base md:text-lg font-semibold mt-1">
        {profile && profile.firstName
          ? `${profile.firstName} ${profile.lastName}`
          : username}
        's QR Code
      </h1>
      <h1>Please scan QR code below:</h1>
      <QRCode value={qrValue} size={200} />
    </div>
  );
};

export default QRCodePage;
