import './global.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Register from './views/auth/Register';
import Confirmation from './views/auth/Confirmation';
import Login from './views/auth/Login';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Link from './views/link/Link';
import UserDisplay from './views/UserDisplay/UserDisplay';
import ForgotPassword from './views/auth/ForgotPassword';
import ResetPassword from './views/auth/ResetPassword';
import QRCodePage from './views/QRCode/QRCode';
import Profile from './views/Profile/Profile';
import PrivateRoute from './components/dashboard/PrivateRoute';
import Design from './views/Design/Design';
import Home from './views/Home/Home';
import Upgrade from './views/upgrade/Upgrade';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/register/confirmation" component={Confirmation} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token+" component={ResetPassword} />
        <Route path="/login" component={Login} />

        <Route exact path="/:username" component={UserDisplay} />
        <Route exact path="/:username/qrcode" component={QRCodePage} />

        <Route exact path="/" component={Home} />

        <DashboardLayout>
          <PrivateRoute path="/dashboard/links" component={Link} />
          <PrivateRoute path="/dashboard/profile" component={Profile} />
          <PrivateRoute path="/dashboard/design" component={Design} />
          <PrivateRoute path="/dashboard/upgrade" component={Upgrade} />
        </DashboardLayout>
      </Switch>
    </Router>
  );
}

export default App;
