import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LINK_TYPE } from '../../../utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // recalculate link position
  let i = 0;
  const finalRes = result.map((item) => {
    i++;
    return { ...item, position: i };
  });

  return finalRes;
};

const ListLink = ({ items, setItems, openModal, updateData }) => {
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.source.index != result.destination.index) {
      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      setItems(newItems);
      let i = 0;
      await Promise.all(
        newItems.map(async (it) => {
          i++;
          const itemId = it.id;
          await updateData(itemId, { ...it, position: i });
        })
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex flex-col space-y-2"
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(
                      { 'bg-gray-200': !item.isShow },
                      'flex flex-row justify-between border rounded-md bg-white h-24'
                    )}
                  >
                    <button
                      className="flex flex-row w-11/12 pl-3 items-center"
                      onClick={() => openModal(false, item.id, item.type)}
                    >
                      {item.img && (
                        <img
                          className="w-20 h-20 object-cover mr-6 rounded"
                          src={`${process.env.REACT_APP_API_URL}/images/${item.img}`}
                          alt="preview"
                        />
                      )}
                      {item.type === LINK_TYPE.LINK ? (
                        <div className="flex flex-col w-full truncate space-y-1 md:flex-row md:items-center md:justify-between md:space-y-0 md:pr-3">
                          <div className="text-left truncate">
                            <p className="text-sm md:text-base font-semibold truncate">
                              {item.title}
                            </p>
                            <p className="text-sm truncate">{item.url}</p>
                          </div>
                          <div className="flex flex-row items-center space-x-1">
                            <FontAwesomeIcon
                              className="text-lg text-gray-400"
                              icon={faEye}
                            />
                            <span className="text-gray-600">
                              {item.countClick}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <p className="text-center w-full font-semibold truncate">
                          {item.title}
                        </p>
                      )}
                    </button>
                    <div className="w-1/12 bg-gray-300"></div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListLink;
