import _ from 'lodash';
import { combineReducers } from 'redux';

// add more resources endpoints here
export const RESOURCE_NAMES = {
  USERS: 'users',
  PROFILES: 'profiles',
  LINKS: 'links',
};

const reducer =
  (resourceName) =>
  (state = {}, action) => {
    let temp = {};
    switch (action.type) {
      case `resources.${resourceName}.set`: {
        const data = _.isArray(action.payload)
          ? action.payload
          : [action.payload];
        return {
          ...state,
          ..._.keyBy(data, 'id'),
        };
      }
      case `resources.${resourceName}.update`:
        return {
          ...state,
          [action.payload.id]: action.payload.data,
        };
      case `resources.${resourceName}.delete`:
        temp = _.cloneDeep(state);
        delete temp[action.payload];
        return temp;
      case `resources.${resourceName}.overwrite`:
        const data1 = _.isArray(action.payload)
          ? action.payload
          : [action.payload];
        return {
          ..._.keyBy(data1, 'id'),
        };
      default:
        return state;
    }
  };

const allReducers = {};
for (let f in RESOURCE_NAMES) {
  allReducers[RESOURCE_NAMES[f]] = reducer(RESOURCE_NAMES[f]);
}

export default combineReducers(allReducers);
